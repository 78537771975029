<template>
  <!--作业试题头部-->
  <div class="Content-right">
    <div class="book-main">
      <div v-for="(it,index) of homeworks" :key="index">
        <div class="homework-title" :title="it.formName">{{ it.formName }}</div>

        <div v-for="(row,index) in it.list" :key="index">
          <!--不含语篇内容-->
          <div class="folderItems" v-if="row.isDiscourse==0">
            <!--原语篇内容-->
            <!--            <div class="questionInfo stem" v-html="it.parentContent"></div>-->
            <!--题号-->
            <div class="questionTitle">
              <div class="T-a">{{row.questionNo}}</div>
              <div class="T-b" v-if="row.score !==''">{{row.score}}分</div>
            </div>
            <!--            <div>{{row.content.docHtmlNoOption}}</div>-->
            <!--选择题-->
            <div class="ContentStem" v-if="row.content.list[0].testSelections !=undefined">
              <div class="stem1" v-html="row.content.docHtmlNoOption"></div>
              <div class="stem2">
                <div v-for="ditems in row.content.list[0].testSelections" :key="ditems.id">
                  <div v-html="ditems.selectionHTML"></div>
                </div>
              </div>
            </div>

            <!--非选择题-->
            <div class="ContentStem" v-else>
              <div class="stem3" v-html="row.content.docHtml"></div>
            </div>


            <!--图书目录试题列表-图书目录-试题详情-->
            <div class="OpeRaTion" v-if="!isShowBtn">

            </div>

            <div class="typeList">
              <div class="typesBody">
                <div class="answerAna">
                  <div class="werAna-A">【参考答案】</div>
                  <div class="werAna-B"><span v-html="row.answer"></span></div>

                </div>
                <div class="ty01">
                  <span @click="row.isDelete=!row.isDelete" v-if="!row.isDelete">查看分析<i class="el-icon-arrow-down"></i></span>
                  <span @click="row.isDelete=!row.isDelete" v-if="row.isDelete">收起分析<i
                      class="el-icon-arrow-up"></i></span>
                </div>
                <!--                                        <div class="ty02">题型：{{row.type}}<span>|</span></div>-->
                <!--                                        <div class="ty02">难易度：{{row.difficulty}}<span>|</span></div>-->
                <!--                                        <div class="ty02">分值：{{row.score}}<span>|</span></div>-->
                <!--                                        <div class="ty03" title="row.knowledge">知识点：{{row.knowledge}}<span>|</span>-->
                <!--                                        </div>-->
                <!--                                        <div class="ty04">测量目标：<span>{{row.target}}</span></div>-->
              </div>
              <transition>
                <div v-show="row.isDelete" class="ComH_fade">
                  <div class="ComH_fade_It" v-if="it.studentAnswer !=''">
                    <div class="werAna-2"><span v-html="it.studentAnswer"></span></div>

                  </div>
                  <div class="ComH_fade_It" v-if="row.analysis !=null">
                    <div class="werAna-1">【试题分析】</div>
                    <div class="werAna-2"><span v-html="row.analysis"></span></div>

                  </div>
                  <div class="ComH_fade_It" v-if="row.answerAnalysis !=null">
                    <div class="werAna-1">【答题分析】</div>
                    <div class="werAna-2"><span v-html="row.answerAnalysis"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.questionUuid !=''">
                    <div class="werAna-1">【试题ID】</div>
                    <div class="werAna-2"><span v-html="row.questionUuid"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.applyScope !=''">
                    <div class="werAna-1">【适用范围】</div>
                    <div class="werAna-2"><span v-html="row.applyScope"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.target !=''">
                    <div class="werAna-1">【测量目标】</div>
                    <div class="werAna-2"><span v-html="row.target"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.series !=''">
                    <div class="werAna-1">【系列】</div>
                    <div class="werAna-2"><span v-html="row.series"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.source !=''">
                    <div class="werAna-1">【试题来源】</div>
                    <div class="werAna-2"><span v-html="row.source"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.source !=''">
                    <div class="werAna-1">【已用去向】</div>
                    <div class="werAna-2"><span>无该字段</span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.knowledge !=''">
                    <div class="werAna-1">【知识内容】</div>
                    <div class="werAna-2"><span v-html="row.knowledge"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.discourseTheme !=''">
                    <div class="werAna-1">【语篇题材】</div>
                    <div class="werAna-2"><span v-html="row.discourseTheme"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.discourseGenre !=''">
                    <div class="werAna-1">【语篇体裁】</div>
                    <div class="werAna-2"><span v-html="row.discourseGenre"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.adaptation !=''">
                    <div class="werAna-1">【是否改编】</div>
                    <div class="werAna-2"><span v-html="row.adaptation"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.discourseWordRange !=''">
                    <div class="werAna-1">【字数范围】</div>
                    <div class="werAna-2"><span v-html="row.discourseWordRange"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.score !=null">
                    <div class="werAna-1">【分数】</div>
                    <div class="werAna-2"><span v-html="row.score"></span></div>
                  </div>
                  <div class="ComH_fade_It" v-if="row.difficulty !=''">
                    <div class="werAna-1">【难易度】</div>
                    <div class="werAna-2"><span v-html="row.difficulty"></span></div>
                  </div>
                </div>
              </transition>

            </div>
          </div>
          <!--含语篇内容-->
          <div class="DiscourseItems" v-if="row.isDiscourse==1">
            <div class="DiscourseBox">
              <el-scrollbar style="height:100%;">
                <div v-html="row.contentMap.discourseContent" style="padding-right: 10px; box-sizing: border-box"></div>
              </el-scrollbar>
            </div>
            <div class="StemBox">
              <el-scrollbar style="height:100%;">
                <div class="folderItemsD" v-for="Disitem in row.contentMap.discourseList" :key="Disitem.id">
                  <div class="questionTitle">
                    <div class="T-a">{{Disitem.questionNo}}</div>
                    <div class="T-b" v-if="Disitem.score !==''">{{Disitem.score}}分</div>
                  </div>
                  <!--题干和选项   选择题-->
                  <div class="ContentStem">
                    <div>
                      <!--选择题-->
                      <div v-if="Disitem.content.list[0].testSelections !=undefined">
                        <div class="stem3">
                          <div v-html="Disitem.content.docHtmlNoOption"></div>
                        </div>
                        <div class="stem4">
                          <div v-for="ditems in Disitem.content.list[0].testSelections" :key="ditems.id">
                            <div v-html="ditems.selectionHTML"></div>
                          </div>
                        </div>
                      </div>
                      <!--非选择题-->
                      <div class="stem3" v-else>
                        <div class="stem3">
                          <div v-html="Disitem.content.docHtml"></div>
                        </div>
                      </div>


                      <div class="OpeRaTion" v-if="!isShowBtn">

                      </div>
                    </div>

                    <!--                                                            <div class="questionInfo stem" v-html="Disitem.content.strSimpleJson"></div>-->
                  </div>


                  <div class="typeList">
                    <div class="typesBody">
                      <div class="answerAna" v-if="Disitem.answer.length>0">
                        <div class="werAna-A">【参考答案】</div>
                        <div class="werAna-B"><span v-html="Disitem.answer"></span></div>

                      </div>
                      <div class="ty01">
                        <span @click="Disitem.isDelete=!Disitem.isDelete" v-if="!Disitem.isDelete">查看分析<i
                            class="el-icon-arrow-down"></i></span>
                        <span @click="Disitem.isDelete=!Disitem.isDelete" v-if="Disitem.isDelete">收起分析<i
                            class="el-icon-arrow-up"></i></span>
                      </div>
                    </div>
                    <transition name="slide-fade">
                      <div v-show="Disitem.isDelete" class="ComH_fade">
                        <div class="ComH_fade_It" v-if="it.studentAnswer !==null">
                          <div class="werAna-2"><span v-html="it.studentAnswer"></span></div>

                        </div>
                        <div class="ComH_fade_It">
                          <div class="werAna-1">【试题分析】</div>
                          <div class="werAna-2"><span v-html="Disitem.analysis"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.answerAnalysis !==null">
                          <div class="werAna-1">【答题分析】</div>
                          <div class="werAna-2"><span v-html="Disitem.answerAnalysis"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.questionUuid !=''">
                          <div class="werAna-1">【试题ID】</div>
                          <div class="werAna-2"><span v-html="Disitem.questionUuid"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.applyScope !=''">
                          <div class="werAna-1">【适用范围】</div>
                          <div class="werAna-2"><span v-html="Disitem.applyScope"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.target !=''">
                          <div class="werAna-1">【测量目标】</div>
                          <div class="werAna-2"><span v-html="Disitem.target"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.series !=''">
                          <div class="werAna-1">【系列】</div>
                          <div class="werAna-2"><span v-html="Disitem.series"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.source !=''">
                          <div class="werAna-1">【试题来源】</div>
                          <div class="werAna-2"><span v-html="Disitem.source"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.knowledge !=''">
                          <div class="werAna-1">【知识内容】</div>
                          <div class="werAna-2"><span v-html="Disitem.knowledge"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.discourseTheme !=''">
                          <div class="werAna-1">【语篇题材】</div>
                          <div class="werAna-2"><span v-html="Disitem.discourseTheme"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.discourseGenre !=''">
                          <div class="werAna-1">【语篇体裁】</div>
                          <div class="werAna-2"><span v-html="Disitem.discourseGenre"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.adaptation !=''">
                          <div class="werAna-1">【是否改编】</div>
                          <div class="werAna-2"><span v-html="Disitem.adaptation"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.discourseWordRange !=''">
                          <div class="werAna-1">【字数范围】</div>
                          <div class="werAna-2"><span v-html="Disitem.discourseWordRange"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.score !=null">
                          <div class="werAna-1">【分数】</div>
                          <div class="werAna-2"><span v-html="Disitem.score"></span></div>
                        </div>
                        <div class="ComH_fade_It" v-if="Disitem.difficulty !=''">
                          <div class="werAna-1">【难易度】</div>
                          <div class="werAna-2"><span v-html="Disitem.difficulty"></span></div>
                        </div>
                      </div>
                    </transition>
                  </div>

                </div>
              </el-scrollbar>
            </div>
          </div>
        </div>


      </div>
    </div>


  </div>

</template>

<script>
//import {} from "@/api/booklist/index";
export default {
  name: 'ComHomeworks',
  props: ['homeworks', 'testQuestions', 'TsubjectId', 'isShowBtn'],
  data() {
    return {
      TSCatalogId:''
    }
  },
  mounted() {
  },
  methods: {
    // 跳转到试题列表


  },
  watch: {
    homeworks: {
      handler() {
        this.homeworks.map(item => {
          if (item.list != null) {
            item.list.map(it => {
              // console.log(it, 'item')
              if (it.isDiscourse == 1) {
                if (it.contentMap.discourseList != null) {
                  it.contentMap.discourseList.map(i => {
                    i.content = JSON.parse(i.content)
                    // console.log(i.content, '9090')
                  })
                }
              } else {
                it.content = JSON.parse(it.content)
                // console.log(it.content, '8888')
              }
            })
          }


          // if (item.homeWorkJson != null ) {
          //   item.homeWorkJson.map(it => {
          //     it.content = JSON.parse(it.content)
          //     console.log(it, 'i90966666666666660909')
          //
          //     if (it.isDiscourse == 1) {
          //       if (it.contentMap.discourseList != null) {
          //         it.contentMap.discourseList.map(i => {
          //           i.content = JSON.parse(i.content)
          //           console.log(i, 'i9090909')
          //
          //         })
          //       }
          //     }
          //   })
          // } else {
          //   item.homeWorkJson = []
          // }

        })
        //console.log(this.homeworks, 'this.homeworks')
      },
      immediate: true
    }

  }

}
</script>

<style lang="scss" scoped>
.Content-right {
  width: 99%;
  margin: auto;
  padding: 10px 0;
  box-sizing: border-box;
  /*导出导入*/
  .B-btnBox {
    height: 60px;
    text-align: right;

    .listbtni {
      margin-bottom: 10px;
    }
  }

  .hd-c {
    height: 60px;
    line-height: 60px;
    border-bottom: 1px dashed #ff6820;
    display: flex;

    .name {
      width: 632px;
      line-height: 60px;
      font-size: 16px;
    }

    .Cbtn-box {
      width: 228px;
      text-align: right;

      .b-1 {
        margin-right: 10px;

        .el-button--primary {
          color: #FFFFFF;
          background-color: rgba(255, 82, 82, 1);
          border-color: rgba(255, 82, 82, 1);
        }
      }

      .b-2 {
        .el-button--primary {
          color: #FFFFFF;
          background-color: #06C8C8;
          border-color: #06C8C8;
        }
      }
    }
  }
}

.book_hd {
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 20px;
  padding-bottom: 6px;
  display: flex;

  img {
    width: 88px;
    height: 121px;
    margin-right: 10px;
  }

  .info {
    width: 192px;
    padding-top: 8px;

    h2 {
      font-size: 14px;
      height: 41px;
      margin-bottom: 6px;
      font-weight: bold;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .time {
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      color: #999999;
    }

    .press {
      height: 30px;
      line-height: 30px;
      color: rgba(102, 102, 102, 1);
      font-size: 14px;
      display: flex;

      img {
        width: 18px;
        height: 14px;
        margin-top: 8px;
      }
    }
  }
}

.book-tree {
  .hd {
    display: block;
    height: 30px;
    line-height: 30px;
    border-bottom: 1px dashed rgba(255, 104, 32, 1);
    margin-bottom: 10px;

    .name {
      width: 100px;
      height: 30px;
      text-align: center;
      float: left;
      color: #fff;
      display: block;
      background: rgba(255, 104, 32, 1);
      border-radius: 12px 12px 0px 0px;
    }

    .edit-btn {
      display: block;
      float: right;
      font-size: 12px;
      font-weight: 400;
      color: rgba(255, 104, 32, 1);
      right: 0;
    }
  }

  .book-treebody {
    height: 41%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  margin-top: 20px;
  height: 50%;

  ::v-deep .el-tree-node__content > .el-tree-node__expand-icon {
    padding: 3px;
    border-radius: 50px;
    margin-right: 6px;
    background-color: rgba(255, 104, 32, 1);
  }

  ::v-deep .el-tree-node__expand-icon {
    cursor: pointer;
    color: #fff;
    /*font-size: 12px;*/
    /*transform: rotate(0);*/
    /*transition: transform .3s ease-in-out;*/
  }

  ::v-deep .el-tree-node__content {
    display: flex;
    align-items: center;
    height: 32px;
    cursor: pointer;
  }

  .span-ellipsis {
  }

  /*width:200px;*/
  .el-tree {
    min-width: 100%;
    font-size: 14px;
    display: inline-block;

    > .span-ellipsis {
      width: 100% !important;
      overflow: hidden !important;
      white-space: nowrap !important;
      text-overflow: ellipsis !important;
      display: block !important;
    }
  }
}


.show-ellipsis {
  display: block !important;
  width: 246px !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  padding-right: 20px !important;
  box-sizing: border-box !important;
}


// 步骤
.step {
  height: 60px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 2px 0px rgba(255, 104, 32, 0.3);
  opacity: 0.9;
  padding: 0 20px;
  display: flex;

  .lf {
    width: 20%;
    height: 60px;
    line-height: 60px;
    color: rgba(255, 104, 32, 1);
    display: flex;

    img {
      width: 22px;
      height: 22px;
      margin-top: 18px;
      margin-right: 10px;
    }
  }

  .rg {
    width: 80%;

    .step-cent {
      width: 869px;
      height: 40px;
      /*background: url("../assets/images/step.png") no-repeat;*/
      margin: 8px auto auto auto;
      display: flex;

      .cent-auot {
        width: 380px;
        height: 40px;
        line-height: 40px;
        display: flex;

        span {
          width: 40px;
          height: 40px;
          text-align: center;
          display: block;
          border-radius: 50px;
          margin-right: 6px;
          border: 1px solid #eee;
        }
      }

      .cent-auot2 {
        width: 120px;
        height: 40px;
        line-height: 40px;
        display: flex;

        span {
          width: 40px;
          height: 40px;
          text-align: center;
          display: block;
          border-radius: 50px;
          margin-right: 6px;
          border: 1px solid #eee;
        }
      }

      .cent-actives {
        span {
          color: #fff;
          border: 1px solid #ff6820;
          background-color: #ff6820;
        }
      }
    }
  }
}

::v-deep .el-checkbox__inner {
  display: inline-block;
  position: relative;
  border: 1px solid #06c8c8;
  border-radius: 2px;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  background-color: #FFF;
  z-index: 1;
  transition: border-color .25s cubic-bezier(.71, -.46, .29, 1.46), background-color .25s cubic-bezier(.71, -.46, .29, 1.46);
}

.check-box {
  span {
    color: #06c8c8;
  }
}

.dTjxbox {
  display: block;
  line-height: 26px;

  .dT {
    width: 90px;
    color: #06c8c8;
  }
}

.book-main {
  display: inline-block;
  border-right: 1px solid #F9F9F9;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;

  /* 不含语篇内容 */
  .homework-title {
    width: 96%;
    height: 50px;
    line-height: 50px;
    text-indent: 10px;
    font-size: 16px;
    white-space:nowrap;
    overflow:hidden;
    text-overflow:ellipsis;
    font-weight: bold;
    background: #F3F3F3;
    border: 2px solid #DDDDDD;
    margin: auto auto 20px auto;
    opacity: 1;
    border-radius: 8px;
  }

  .folderItems {
    width: 96%;
    position: relative;
    cursor: pointer;
    border: 1px solid #E6E6E6;
    padding: 10px 0px 0 0px;
    box-sizing: border-box;
    font-size: 12px;
    padding-top: 50px;
    border-radius: 8px;
    color: #666;
    font-weight: normal;
    font-family: MicrosoftYaHei;
    margin: auto auto 20px auto;
    /* 题号 */
    .questionTitle {
      width: 97%;
      height: 30px;
      line-height: 30px;
      box-sizing: border-box;
      font-size: 14px;
      color: #fff;
      position: absolute;
      margin-top: -40px;
      font-weight: normal;

      .T-a {
        height: 30px;
        line-height: 30px;
        padding-right: 10px;
        box-sizing: border-box;
        border-radius: 0 30px 30px 0;
        background: #0090DA;
        left: -10px;
        padding-left: 20px;
        position: absolute;
        z-index: 99;
        font-size: 18px;
        color: #fff;
      }

      .T-b {
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: right;
        color: #0090DA;
        padding-left: 20px;
        position: absolute;
        z-index: 99;
        right: 0;
        font-size: 14px;
      }
    }

    /* 题干和选项 */
    .ContentStem {
      width: 100%;
      padding: 0 9px;
      box-sizing: border-box;
      word-wrap: break-word;
      word-break: normal;
      display: block;

      .stem1 {
        width: 100%;
        padding-right: 20px;
        box-sizing: border-box;
        float: left;
      }

      .stem2 {
        width: 100%;
        float: left;
        word-break: keep-all;
        word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
        white-space: pre-wrap; //只对中文起作用，强制换行。
        text-align: justify; //css英文语句的两端对齐：
        text-justify: inter-ideograph;
      }

      .stem3 {
        width: 100%;
        padding-right: 20px;
        box-sizing: border-box;
        float: left;
        word-wrap: break-word;
        white-space: normal;
        word-break: break-all;
        word-break: normal;
      }

      .stem4 {
        width: 50%;
        float: left;
        word-break: keep-all;
        word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
        white-space: pre-wrap; //只对中文起作用，强制换行。
        text-align: justify; //css英文语句的两端对齐：
        text-justify: inter-ideograph;
      }
    }

    .checkedItem {
      position: absolute;
      top: 0;
      right: 0;
    }


    .answerInfo {
      font-size: 12px;
      line-height: 20px;

      .answerAna {
        font-size: 12px;
        color: #07C8C8;

        span {
          color: #343434;
        }
      }
    }
  }


  /* 含语篇内容 */
  .DiscourseItems {
    width: 96%;
    height: 510px;
    background: #F3F3F3;
    border: 2px solid #DDDDDD;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 8px;
    padding: 0 0px;
    margin: auto auto 20px auto;
    padding: 10px;
    box-sizing: border-box;

    .homework-title {
      width: 96%;
      height: 50px;
      line-height: 50px;
      text-indent: 10px;
      font-size: 16px;
      font-weight: bold;
      background: #F3F3F3;
      border: 2px solid #DDDDDD;
      opacity: 1;
      border-radius: 8px;
    }

    /* 语篇内容 */
    .DiscourseBox {
      width: 100%;
      height: 200px;
      margin: auto;
      padding: 0 10px 0 0;
      box-sizing: border-box;
      overflow-y: auto;
      word-wrap: break-word;
      white-space: normal;
      word-break: break-all;
      word-break: normal;
    }

    /* 题干和内容 */
    .StemBox {
      width: 98%;
      height: 270px;
      background-color: #fff;
      padding: 10px;
      box-sizing: border-box;
      margin: 10px auto auto auto;
      overflow-y: auto;

      .folderItemsD {
        position: relative;
        cursor: pointer;
        border: 1px solid #E6E6E6;
        padding: 10px 0px 0 0px;
        box-sizing: border-box;
        font-size: 12px;
        padding-top: 50px;
        color: #666;
        font-weight: normal;
        font-family: MicrosoftYaHei;
        margin: auto auto 20px auto;
        /* 题号 */
        .questionTitle {
          width: 97%;
          height: 30px;
          line-height: 30px;
          box-sizing: border-box;
          font-size: 14px;
          color: #fff;
          position: absolute;
          margin-top: -40px;
          font-weight: normal;

          .T-a {
            padding-right: 10px;
            box-sizing: border-box;
            height: 30px;
            line-height: 30px;
            background: #0090DA;
            border-radius: 0 30px 30px 0;
            left: -10px;
            padding-left: 20px;
            position: absolute;
            z-index: 99;
            font-size: 18px;
            color: #fff;
          }

          .T-b {
            width: 60px;
            height: 30px;
            line-height: 30px;
            text-align: right;
            color: #0090DA;
            padding-left: 20px;
            position: absolute;
            z-index: 99;
            right: 0;
            font-size: 14px;
          }
        }

        /* 题干和选项 */
        .ContentStem {
          width: 100%;
          padding: 0 9px;
          box-sizing: border-box;
          text-align: left;
          font-size: 14px;
          word-wrap: break-word;
          white-space: normal;
          word-break: break-all;
          word-break: normal;

          .stem1 {
            width: 100%;
            padding-right: 20px;
            box-sizing: border-box;
            text-align: left;
            word-wrap: break-word;
            white-space: normal;
            word-break: break-all;
            float: left;
          }

          .stem2 {
            width: 100%;
            float: left;
            word-break: keep-all;
            word-wrap: break-word; // 只对英文起作用，以单词作为换行依据。
            white-space: pre-wrap; //只对中文起作用，强制换行。
            text-align: justify; //css英文语句的两端对齐：
            text-justify: inter-ideograph;
          }
        }

        .typeList {
          margin-top: 10px;

          .typesBody {
            width: 100%;
            padding: 10px 10px;
            box-sizing: border-box;
            display: inline-flex;
            background-color: #EBF5FB;
            position: relative;

            .answerAna {
              width: 70%;
              float: left;
              color: #585858;
              display: flex;
              float: left;
              font-family: 方正书宋_GBK;
              font-size: 14px;
              vertical-align: baseline;
              position: relative;

              .werAna-A {
                width: 100px;
                float: left;
                position: relative;
              }

              .werAna-B {
                width: 70%;
                padding-left: 100px;
                box-sizing: border-box;
                float: left;
              }
            }


            .ty01 {
              width: 150px;
              padding-left: 30px;
              color: #BBBBBB;
              box-sizing: border-box;
              cursor: pointer;
              position: absolute;
              right: 0;
              display: flex;

              i {
                font-size: 14px;
              }
            }

            .ty02 {
              height: 40px;
              float: left;

              span {
                margin: 0px 10px;
              }

            }

            .ty03 {
              width: 180px;
              height: 40px;
              float: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              span {
                margin: 0px 10px;
              }

              margin-right: 10px;
            }

            .ty04 {
              width: 180px;
              height: 40px;
              float: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .ComH_fade {
            border-top: 1px solid #ddd;
            background: #EBF5FB;
            font-size: 14px;
            padding: 10px;
            box-sizing: border-box;

            .ComH_fade_It {
              width: 100%;
              display: flex;
              position: relative;
              margin-bottom: 10px;

              .werAna-1 {
                width: 100px;
                float: left;
                color: #0090DA;
                position: absolute;
              }

              .werAna-2 {
                width: 100%;
                padding-left: 100px;
                box-sizing: border-box;
                float: left;
              }
            }

          }

          .types {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background-color: #EBF5FB;

            .answerAna {
              width: 70%;
              float: left;
              color: #585858;
              float: left;
              display: flex;
              font-size: 14px;
              vertical-align: baseline;

              .werAna-A {
                width: 100px;
                float: left;
              }

              .werAna-B {
                width: 70%;
                float: left;
              }
            }

            .ty01 {
              width: 100px;
              height: 40px;
              padding-left: 30px;
              color: #BBBBBB;
              box-sizing: border-box;
              cursor: pointer;
              float: right;
              display: flex;

              i {
                font-size: 14px;
              }
            }

            .ty02 {
              height: 40px;
              float: left;

              span {
                margin: 0px 10px;
              }

            }

            .ty03 {
              width: 180px;
              height: 40px;
              float: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              span {
                margin: 0px 10px;
              }

              margin-right: 10px;
            }

            .ty04 {
              width: 180px;
              height: 40px;
              float: left;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        .checkedItem {
          position: absolute;
          top: 0;
          right: 0;
        }


      }
    }
  }
}


.mainDiv {
  background-color: #ffffff;
  margin-top: 20px;
  padding: 20px 0px 20px 0px;
  height: auto;
  overflow: hidden;
}

.mainDiv .leftDiv {
  float: left;
  width: 250px;
  border-right: 1px dashed #dddddd;
  padding-right: 9px;
}

.mainDiv .rightDiv {
  float: left;
  width: 915px;
  padding: 0;
  margin: auto;
  margin-left: 15px;
}

.mainDiv .rightDiv h2 {
  text-align: center;
}

.mainDiv .rightDiv .el-table {
  width: 100%;
  font-size: 14px;
}

.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}

.questionInfo {
  font-size: 12px;
  line-height: 20px;
  word-wrap: break-word;
  word-break: normal;
}

.typeList {
  margin-top: 10px;

  .typesBody {
    width: 100%;
    padding: 10px 10px;
    box-sizing: border-box;
    display: inline-flex;
    background-color: #EBF5FB;
    position: relative;

    .answerAna {
      width: 70%;
      float: left;
      color: #585858;
      float: left;
      display: flex;
      font-size: 14px;
      vertical-align: baseline;

      .werAna-A {
        width: 100px;
        float: left;
      }

      .werAna-B {
        width: 70%;
        float: left;
      }
    }


    .ty01 {
      width: 150px;
      padding-left: 30px;
      color: #BBBBBB;
      box-sizing: border-box;
      cursor: pointer;
      position: absolute;
      right: 0;
      display: flex;

      i {
        font-size: 14px;
      }
    }

    .ty02 {
      height: 40px;
      float: left;

      span {
        margin: 0px 10px;
      }

    }

    .ty03 {
      width: 180px;
      height: 40px;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        margin: 0px 10px;
      }

      margin-right: 10px;
    }

    .ty04 {
      width: 180px;
      height: 40px;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ComH_fade {
    border-top: 1px solid #ddd;
    background: #EBF5FB;
    font-size: 14px;
    padding: 10px;
    box-sizing: border-box;

    .ComH_fade_It {
      width: 100%;
      display: flex;
      position: relative;
      margin-bottom: 10px;

      .werAna-1 {
        width: 100px;
        float: left;
        color: #0090DA;
        position: absolute;
      }

      .werAna-2 {
        width: 100%;
        padding-left: 100px;
        box-sizing: border-box;
        float: left;
      }
    }

  }

  .types {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #EBF5FB;

    .answerAna {
      width: 70%;
      float: left;
      color: #585858;
      float: left;
      font-family: 方正书宋_GBK;
      font-size: 14px;
      vertical-align: baseline;

      .werAna-A {
        width: 100px;
        float: left;
      }

      .werAna-B {
        width: 70%;
        float: left;
      }
    }

    .ty01 {
      width: 100px;
      height: 40px;
      padding-left: 30px;
      color: #BBBBBB;
      box-sizing: border-box;
      cursor: pointer;
      float: right;
      display: flex;

      i {
        font-size: 14px;
      }
    }

    .ty02 {
      height: 40px;
      float: left;

      span {
        margin: 0px 10px;
      }

    }

    .ty03 {
      width: 180px;
      height: 40px;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        margin: 0px 10px;
      }

      margin-right: 10px;
    }

    .ty04 {
      width: 180px;
      height: 40px;
      float: left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}


.btnNext {
  text-align: right;
  position: fixed;
  right: 20px;
  bottom: 200px;
  z-index: 100;
}

.btnNext button {
  width: 80px;
  margin-top: 15px;
  padding: 10px 0px;
}

::v-deep .expanded.el-tree-node__expand-icon.el-icon-plus {
  padding: 1px;
  border-radius: 50%;
  background-color: #FF6820;
  color: #fff;
  font-size: 6px !important;
  margin-right: 6px;

}

.nodata-Box {
  margin: auto;
  width: 255px;
  padding: 0;
  text-align: center;
  color: #999;

  img {
    width: 255px;
    height: 220px;
    margin: auto;
  }
}

.rightTable {
  display: inline-block;
  width: 50%;
  vertical-align: top;
  height: 100%;
}

.slide-fade-enter-active {
  transition: all 0.3s;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

/*编辑与删除*/
.OpeRaTion {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
  text-align: right;
}
</style>
